@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  [dir='rtl'] .datepicker {
    .react-datepicker__navigation.react-datepicker__navigation--next {
      left: 2px;
      right: unset !important;
      transform: rotateY(180deg);
    }
    .react-datepicker__navigation.react-datepicker__navigation--previous {
      left: unset !important;
      right: 2px;
      transform: rotateY(180deg);
    }
  }
  .datepicker {
    .react-datepicker {
      @apply border-none w-full rounded-1.5 bg-canvas-light dark:bg-canvas-dark mbe-1;
    }

    .react-datepicker__header {
      @apply bg-transparent border-none rounded-1.5 p-0;
    }

    .react-datepicker__navigation {
      @apply top-2;
    }

    .react-datepicker__navigation-icon {
      @apply before:border-text-light before:dark:border-text-dark;
    }

    .react-datepicker__current-month {
      @apply text-text-light dark:text-text-dark text-19 font-medium p-1.5;
    }

    .react-datepicker__month-container {
      @apply w-full;
    }

    .react-datepicker__month {
      @apply m-0;
    }

    .react-datepicker__day-name {
      @apply inline-block m-0 text-center w-[calc(100%/7)] text-13 font-bold uppercase text-text-light dark:text-text-dark;
    }

    .react-datepicker__week {
      @apply flex justify-around;
    }

    .react-datepicker__day.react-datepicker__day--selected {
      @apply bg-blue-30 dark:bg-gray-20 text-canvas-light dark:text-canvas-secondary-dark hover:bg-blue-30 hover:dark:bg-gray-20;
    }

    .react-datepicker__day.react-datepicker__day--outside-month.react-datepicker__day--selected {
      @apply bg-transparent text-transparent pointer-events-none;
    }

    .react-datepicker__day--keyboard-selected {
      @apply bg-transparent;
    }

    .react-datepicker__day {
      @apply flex items-center justify-center w-[calc(100%/7)] text-19 font-regular aspect-square max-w-[48px] max-h-[48px] rounded-full text-text-light dark:text-text-dark hover:text-text-dark hover:bg-gray-15 dark:hover:bg-gray-40 hover:rounded-full;
    }

    .react-datepicker__day--disabled {
      @apply text-gray-15 dark:text-gray-35 hover:text-gray-15 hover:dark:text-gray-35 hover:bg-transparent;
    }

    .react-datepicker__day.react-datepicker__day--outside-month {
      @apply bg-transparent text-transparent pointer-events-none;
    }

    .react-datepicker__day--today {
      @apply text-blue-30 dark:text-gray-20;
    }
  }
}
